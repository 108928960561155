import React, { FC, ReactNode } from 'react'

import PropTypes from 'prop-types'
import { CFormControlValidation, CFormFloating, CFormLabel, CFormText } from '@coreui/react-pro';
import { CFormControlValidationProps } from '@coreui/react-pro/dist/components/form/CFormControlValidation';



export interface RFormControlWrapperProps extends CFormControlValidationProps {
  /**
   * @ignore
   */
  children?: ReactNode
  /**
   * A string of all className you want applied to the floating label wrapper.
   *
   * @since 4.5.0
   */
  floatingClassName?: string
  /**
   * Provide valuable, actionable valid feedback when using standard HTML form validation which applied two CSS pseudo-classes, `:invalid` and `:valid`.
   *
   * @since 4.2.0
   */
  floatingLabel?: ReactNode | string
  /**
   * @ignore
   */
  id?: string
  /**
   * Add a caption for a component.
   *
   * @since 4.2.0
   */
  label?: ReactNode | string
  /**
   * Add helper text to the component.
   *
   * @since 4.2.0
   */
  text?: ReactNode | string
}

export const RFormControlWrapper: FC<RFormControlWrapperProps> = ({
                                                                    children,
                                                                    describedby,
                                                                    feedback,
                                                                    feedbackInvalid,
                                                                    feedbackValid,
                                                                    floatingClassName,
                                                                    floatingLabel,
                                                                    id,
                                                                    invalid,
                                                                    label,
                                                                    text,
                                                                    tooltipFeedback,
                                                                    valid,
                                                                  }) => {
  const formControlValidation = () => (
    <CFormControlValidation
      describedby={describedby}
      feedback={feedback}
      feedbackInvalid={feedbackInvalid}
      feedbackValid={feedbackValid}
      floatingLabel={floatingLabel}
      invalid={invalid}
      tooltipFeedback={tooltipFeedback}
      valid={valid}
    />
  )
  return floatingLabel ? (
    <CFormFloating className={floatingClassName}>
      {children}
      <CFormLabel htmlFor={id}>{label || floatingLabel}</CFormLabel>
      {text && <CFormText id={describedby}>{text}</CFormText>}
      {formControlValidation()}
    </CFormFloating>
  ) : (
    <>
      {label && <CFormLabel htmlFor={id}>{label}</CFormLabel>}
      {children}
      {text && <CFormText id={describedby}>{text}</CFormText>}
      {formControlValidation()}
    </>
  )
}

RFormControlWrapper.propTypes = {
  children: PropTypes.node,
  floatingClassName: PropTypes.string,
  floatingLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  text: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  ...CFormControlValidation.propTypes,
}

RFormControlWrapper.displayName = 'CFormControlWrapper'
