// TODO: check if component property is necessary.

import React, { forwardRef, HTMLAttributes } from 'react'

export const RMultiSelectCleaner = forwardRef<HTMLButtonElement, HTMLAttributes<HTMLButtonElement>>(
  (props, ref) => {
    return (
      <button
        type="button"
        className="form-multi-select-selection-cleaner"
        {...props}
        ref={ref}
      ></button>
    )
  },
)

RMultiSelectCleaner.displayName = 'CMultiSelectCleaner'
