import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CDatePicker } from '@coreui/react-pro';
import { ColumnFilterProps } from '@smart-table/types';
import storage from '@storage';
import './date-filter.scss';

/**
 * Компонет фильтра дат
 */
export default function DateFilter(props: ColumnFilterProps) {
  const date = useSelector(storage.smartTable.selectFilter<string>(props.tableName, props.columnKey));
  const dispatch = useDispatch();

  return (
    <CDatePicker
      size='sm'
      locale='ru-RU'
      placeholder='00.00.0000'
      date={date}
      onDateChange={(date) => {
        dispatch(storage.smartTable.setFilter(
          props.tableName,
          props.columnKey,
          date?.toISOString()
        ));
      }}
    />
  );
}