import React, { useCallback, useState } from 'react';
import { CDatePicker } from '@coreui/react-pro';
import { Nullable } from '@types';
import { FormLabel } from '@component';
import './date-picker.scss';

interface DatePickerProps {
  name?: string;
  label?: string;
  description?: string | JSX.Element;
  placeholder?: string;
  defaultValue?: string;
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  onDateChange?: (date: Nullable<Date>, formattedDate?: string) => void;
  timepicker?: boolean;
  date?: string | Date | null;
  className?: string;
  invalid?: boolean;
  feedbackInvalid?: string;
}

export default function DatePicker(props: DatePickerProps) {
  const [date, setDate] = useState(props.defaultValue ?? props.date);

  const onDateChange = useCallback((newDate: Nullable<Date>, formattedDate?: string) => {
    setDate(newDate?.toISOString());
    props.onDateChange?.call(null, newDate, formattedDate);
  }, [props.onDateChange]);


  return (
    <>
      {props.label && (
        <FormLabel description={props.description} hidden={props.hidden} required={props.required}>
          {props.label}
        </FormLabel>
      )}
      <CDatePicker
        invalid={props.invalid}
        feedbackInvalid={props.feedbackInvalid}
        date={date}
        locale="ru-RU"
        placeholder={props.placeholder ?? 'Выберите дату'}
        onDateChange={onDateChange}
        inputReadOnly={props.readOnly}
        disabled={props.disabled}
        hidden={props.hidden}
        timepicker={props.timepicker}
        cancelButton={'Отмена'}
        todayButton={'Сегодня'}
        confirmButton={'ОК'}
        className={props.className}
      />
      <input
        type='text'
        name={props.name}
        value={typeof date === 'string' ? date : date?.toISOString() ?? ''}
        onChange={() => {}}
        required={props.required}
        style={{
          width: 0,
          height: 0,
          opacity: 0,
          margin: 0,
          padding: 0,
          position: 'absolute',
          marginLeft: 100
        }}
      />
    </>
  );
}