import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Option } from '@coreui/react-pro/dist/components/multi-select/CMultiSelect';
import { IOption } from '@types';
import { FormLabel } from '@component';
import { RMultiSelect } from './multi-select-copy/r-multi-select';
import { CMultiSelect } from '@coreui/react-pro';

interface FormMultiSelectProps {
  name?: string;
  label?: string;
  description?: string | JSX.Element;
  placeholder?: string;
  hidden?: boolean;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  options?: IOption[];
  defaultValue?: string | string[];
  valueType?: 'string';
  filterOptions?: (option: IOption) => boolean | undefined;
  virtualScroller?: boolean;
  visibleItems?: number;
  selectAll?: boolean;
  disabledIfEmpty?: boolean;
  onChange?: (selected: IOption[]) => void;
  size?: 'sm' | 'lg';
  className?: string;
  searchable?: boolean
  onSearchChange?: (value: string) => void;
  onOptionsScroll?: (currentIndex: number) => void;
}

export default function FormMultiSelect(props: FormMultiSelectProps) {
  const ref = useRef<HTMLDivElement>(null);

  const defaultValue = useMemo(() =>
      typeof props.defaultValue === 'string'
        ? props.valueType === 'string'
          ? props.defaultValue.split(',').map(x => x.trim())
          : [props.defaultValue]
        : props.defaultValue ?? undefined,
    [props.defaultValue]);

  const [values, setValues] = useState<string[]>(defaultValue ?? []);
  const visibleItems = props.visibleItems ?? 6;

  const options = useMemo(() => {
    const data = props.options?.map(option => {
      const newOption: IOption = { ...option };
      newOption.text = newOption.label;
      if (newOption.value) {
        newOption.selected = values.includes(newOption.value.toString()) || option.selected;
      }
      return newOption;
    }) ?? [];

    const items = data.filter(x => x.selected === true || !(x as any).hidden);

    return props.filterOptions
      ? items.filter(props.filterOptions)
      : items;

  }, [props.options, values, props.filterOptions]);

  const onChange = useCallback((selected: Option[]) => {
    props.onChange?.call(null, selected as IOption[]);
    setValues(selected.filter(x => !!x.value).map(x => x.value!.toString()));
  }, [props.onChange]);

  useEffect(() => {
    if (ref.current) {
      const select = ref.current.getElementsByTagName('select')[0];
      if (select && props.name) {
        select.name = props.name;
        select.setAttribute('datatype', 'multi-select');

        // TODO: временный костыль валидации обязательности поля.
        //  После реализации общей валидации убрать!!!
        if (props.required) {
          select.required = true;
          select.style.display = 'block';
          select.style.width = '0';
          select.style.height = '0';
          select.style.opacity = '0';
          select.style.position = 'absolute';
          select.style.marginTop = '36px';
          select.style.marginLeft = '100px';
        }
      }
    }
  }, [ref.current, props.name, props.required]);

  useEffect(() => {
    if (ref.current) {
      const select = ref.current.getElementsByTagName('select')[0];
      if (select) {
        for (let i = 0; i < select.selectedOptions.length; i++) {
          const option = select.selectedOptions.item(i);
          if (option) {
            if (!options.find(x => x.value == option.value)) {
              const element = ref.current.getElementsByClassName('form-multi-select-selection-cleaner');
              if (element && element[0]) {
                const event = new MouseEvent('click', { bubbles: true, cancelable: true });
                element[0].dispatchEvent(event);
              }
            }
          }
        }
      }
    }
  }, [ref.current, props.name, options]);

  return (
    <div ref={ref} hidden={props.hidden}>
      {props.label && (
        <FormLabel description={props.description}>
          {props.label}
        </FormLabel>
      )}
      {props.searchable ?
        <RMultiSelect
          placeholder={props.placeholder ?? 'Выбрать...'}
          defaultValue={defaultValue}
          disabled={props.disabled || (props.disabledIfEmpty && (!options || options?.length === 0))}
          options={options}
          selectionType="text"
          onChange={onChange}
          selectAllLabel={'Выбрать все'}
          searchNoResultsLabel={'Результатов не найдено'}
          hidden={props.hidden}
          virtualScroller={props.virtualScroller}
          visibleItems={visibleItems}
          selectAll={props.selectAll === undefined ? false : props.selectAll}
          size={props.size}
          className={props.className}
          search={'external'}
          onFilterChange={(value) => {
            if (props.onSearchChange)
              props.onSearchChange(value)}
          }
          onOptionsScroll={props.onOptionsScroll}
        /> :
        <CMultiSelect
          placeholder={props.placeholder ?? 'Выбрать...'}
          defaultValue={defaultValue}
          disabled={props.disabled || (props.disabledIfEmpty && (!options || options?.length === 0))}
          options={options}
          selectionType="text"
          onChange={onChange}
          selectAllLabel={'Выбрать все'}
          searchNoResultsLabel={'Результатов не найдено'}
          hidden={props.hidden}
          virtualScroller={props.virtualScroller}
          visibleItems={visibleItems}
          selectAll={props.selectAll === undefined ? false : props.selectAll}
          size={props.size}
          className={props.className}
        />
      }
    </div>
  );
}