import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '@storage/types';
import { ListApiResponse } from '@api/types';
import { apiClients } from '@api';
import { IOption, ObjectMap } from '@types';
import { batch } from 'react-redux';
import storage from '@storage';

interface IDropdownState {
  actionUrl: string,
  params: ObjectMap;
  emptyOption?: boolean;
  loading: boolean,
  items: IOption[],
  total: number,
}

const initialState: ObjectMap<IDropdownState> = {};

function createState(): IDropdownState {
  return {
    actionUrl: '',
    params: {},
    loading: false,
    items: [],
    total: 0
  };
}

const slice = createSlice({
  name: 'dropdown',
  initialState,
  reducers: {
    setActionUrl: (state, action: PayloadAction<{ name: string, actionUrl?: string }>) => {
      state[action.payload.name] ??= createState();
      state[action.payload.name].actionUrl = action.payload.actionUrl ?? '';
    },
    setParam: (state, action: PayloadAction<{ name: string, paramKey: string, paramValue?: any }>) => {
      state[action.payload.name] ??= createState();
      state[action.payload.name].params[action.payload.paramKey] = action.payload.paramValue;
    },
    setEmptyOption: (state, action: PayloadAction<{ name: string, emptyOption?: boolean }>) => {
      state[action.payload.name] ??= createState();
      state[action.payload.name].emptyOption = action.payload.emptyOption;
    },
    setLoading: (state, action: PayloadAction<{ name: string, loading?: boolean }>) => {
      state[action.payload.name] ??= createState();
      state[action.payload.name].loading = action.payload.loading ?? false;
    },
    setItems: (state, action: PayloadAction<{ name: string, items?: any[] }>) => {
      state[action.payload.name] ??= createState();
      state[action.payload.name].items = action.payload.items ?? [];
    },
    setTotal: (state, action: PayloadAction<{ name: string, total?: number }>) => {
      state[action.payload.name] ??= createState();
      state[action.payload.name].total = action.payload.total ?? 0;
    }
  }
});

const { setActionUrl, setParam, setEmptyOption, setLoading, setItems, setTotal } = slice.actions;

const dropdown = {
  setLoading: (name: string, loading?: boolean) => setLoading({ name, loading }),
  setItems: (name: string, items: IOption[]) => setItems({ name, items }),
  setProps: (name: string, actionUrl: string, params?: ObjectMap, loading?: boolean, emptyOption?: boolean): AppThunk => async (dispatch, getState) => {
    const dropdown = getState().dropdown[name] ?? createState();
    const stateParams = dropdown.params;
    const newParams = params ?? {};

    let dirty = false;

    batch(() => {
      for (const [key, value] of Object.entries(newParams)) {
        const stateValue = stateParams[key];
        if (stateValue != value) {
          dispatch(setParam({ name, paramKey: key, paramValue: value }));
          dirty = true;
        }
      }

      for (const [key, stateValue] of Object.entries(stateParams)) {
        const value = newParams[key];
        if (value == undefined && value !== stateValue) {
          dispatch(setParam({ name, paramKey: key, paramValue: value }));
          dirty = true;
        }
      }

      if (dropdown.actionUrl != actionUrl) {
        dispatch(setActionUrl({ name, actionUrl }));
        dirty = true;
      }

      if (dropdown.emptyOption != emptyOption) {
        dispatch(setEmptyOption({ name, emptyOption }));
        dirty = true;
      }
    });

    if (dirty) {
      dispatch(setLoading({ name, loading: true }));
    }
  },
  selectLoading: (name: string) => (state: RootState) => state.dropdown[name]?.loading ?? false,
  selectItems: (name: string) => (state: RootState) => state.dropdown[name]?.items ?? [],
  selectTotal: (name: string) => (state: RootState) => state.dropdown[name]?.total ?? 0,
  loadData: (name: string): AppThunk => async (dispatch, getState) => {
    try {
      const dropdown = getState().dropdown[name] ?? createState();
      dispatch(storage.backdropSpinner.setVisible(true));

      const response = await apiClients.default.get<ListApiResponse<IOption>>(dropdown.actionUrl, { params: dropdown.params });
      if (response.errorCode) {
        return;
      }

      if (response.items && dropdown.emptyOption) {
        response.items.unshift({ text: undefined });
      }

      const page: number = dropdown.params?.page;
      const currentItems = dropdown.items;

      const items = (page === 1 || page === undefined ?
          response.items :
          currentItems.concat(response.items ?? [])
      )?.map((item) => {
        if (item) {
          return { ...item, label: item.text };
        }
        return item;
      });

      dispatch(setItems({ name, items: items }));
      dispatch(setTotal({ name, total: items?.length !== response.total ? items?.length : response.total }));
    } finally {
      dispatch(setLoading({ name, loading: false }));
      dispatch(storage.backdropSpinner.setVisible(false));
    }
  }
};

export const dropdownReducer = slice.reducer;
export default dropdown;